// $white: #ffffff;
// $dark: #5C6784;
// $turquoise: #8EC6C5;
// $coral: #E08D79;
// $pale: #F6E9D8;

@import "../node_modules/bootstrap/scss/bootstrap";

body {
  padding-top: 60px;
  padding-bottom: 40px;
}

a {
  text-decoration: none;
}

.navbar {
  padding-left: 10px;
  padding-right: 10px;
  // background-color: $dark;
  // color: $white; 
}

.footer .links {
  margin: 0 auto;
  text-align: center;
}

.profile_small {
  width: 175px;
  border-radius: 50%;
}

.profile_large {
  width: 70%;
  border-radius: 50%;
}

.resume .header {
  text-align: center;
}

.home .header {
  text-align: center;
}

.about p {
  padding-left: 1em;
}

.starRating {
  display: block;
  float: right;
}

.icons {
  padding-right: 3px;
  padding-left: 3px;
}